.blf-img-container {
  width: 100%;
  position: relative;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 0%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.blf-container-ratio {
  width: 100%;
  position: relative;

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}