.money-lookup-item-container {
  position: relative;
  p {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 12px;
  }

  border-bottom: 1px solid rgba(0,0,0,0.2);

  label {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);

    input[type=checkbox] {
      margin-right: 5px;
      transform: translateY(2px);
    }
  }

  
}

.type-select-container {
  display: flex;
  justify-content: center;
  align-items: center;
}