@font-face {
	font-family: Abel;
	src: url(../../assets/fonts/Abel-Regular.woff2) format("woff2")
}

@font-face {
	font-family: "NHL Washington";
	src: url(../../assets/fonts/NHLWashington.woff2) format("woff2")
}

.image-money-container {

  &.vnd1000 {
    position: relative;
    .code-seri {
      position: absolute;
      // color: #c23927;
      font-weight: bold;
      color: transparent;
      letter-spacing: 3px;
      // text-shadow: 0 0 1.3px rgba(194, 57, 39, 1);

      @media screen and (max-width: 480px) {
        letter-spacing: 1px;
      }
    }
  }

  .code {
    font-family: "NHL Washington",sans-serif;
  }
  .seri {
    font-family: Abel,sans-serif;
  }

  &.vnd10000 {
    position: relative;
    .code-seri-vertical {
      position: absolute;
      top: 20%;
      left: 2%;
      color: #cb441d;
    }
    .code-seri-horizontal {
      position: absolute;
      bottom: 4%;
      right: 5%;
      color: #2c2730;
    }

    .code-seri {
      position: absolute;
      font-weight: bold;
      color: transparent;
      letter-spacing: 3px;
      // text-shadow: 0 0 1.3px rgba(194, 57, 39, 1);

      @media screen and (max-width: 480px) {
        letter-spacing: 1px;
      }
    }
  }

  &.vnd20000 {
    position: relative;
    .code-seri-vertical {
      position: absolute;
      top: 20%;
      left: 2%;
      color: #cb441d;
    }
    .code-seri-horizontal {
      position: absolute;
      bottom: 4%;
      right: 5%;
      color: #2c2730;
    }
  }

  &.vnd50000 {
    position: relative;
    .code-seri-vertical {
      position: absolute;
      top: 24%;
      left: 3%;
      color: #cb441d;
    }
    .code-seri-horizontal {
      position: absolute;
      bottom: 4%;
      right: 5%;
      color: #2c2730;
    }
  }

  &.usd1 {
    position: relative;
    .code-seri {
      position: absolute;
      // color: #c23927;
      font-weight: bold;
      color: transparent;
      // letter-spacing: 5px;
      // text-shadow: 0 0 1.3px rgba(194, 57, 39, 1);

      // @media screen and (max-width: 600px) {
      //   letter-spacing: 3px;
      // }
    }
  }
}