.middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex1 {
  display: flex;
  flex: 1;
}

.flex-r {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flex-c {
  display: flex;
  flex-direction: column;
}

.cursor {
  cursor: pointer;
}

td > a {
  text-decoration: none;
  color: black;
}

.react-select .react-select__menu .react-select__option, .react-select .react-select__menu .react-select__option:active {
  // background-color: white;
}